import React from 'react'
import {Link} from 'gatsby'
import moment from 'moment'

const BlogCard = ({ data }) => {
    return (
        <div className="blog-area pb-120">
            <div className="container">
                <div className="row">
                    {data.map(post =>(
                        <div key={post.node.id && post.node.frontmatter.publishToProd} className="col-lg-4 col-md-6">
                            {post.node.frontmatter.publishToProd && <div className="single-blog-post">
                                <div className="post-image">
                                    <Link to={`/${post.node.frontmatter.templateKey}/${post.node.frontmatter.path}`}>
                                        <img className='blog-cards-img' src={post.node.frontmatter.image} alt={`BlueMail ${post.node.frontmatter.blogTitle}`} />
                                    </Link>
                                </div>
                                <div className="post-content">
                                    {/*}
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        
                                        <li>
                                            <i className='flaticon-calendar'></i> 
                                            <div style={{marginLeft: '22px'}}>
                                            { moment(post.node.frontmatter.date).format('ll')}
                                            </div>
                                        </li>
                                
                                    </ul>
                                    */}
                                    <Link className='link-box' to={`/${post.node.frontmatter.templateKey}/${post.node.frontmatter.path}`}>
                                        <h3 className='category'>{post.node.frontmatter.category}</h3>
                                        <h3>{post.node.frontmatter.blogTitle}</h3>
                                    </Link>
                                </div>
                            </div>
                            }
                        </div>
                        
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogCard